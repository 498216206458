<template lang="">
  <div class="wrapper" id="contact">
    <div class="top">
      <Headline name="Wie können wir Ihnen helfen?" size="medium" />
      <Text
        content="Wir unterstützen Sie bei der Beschaffung jeglicher Verbrauchsmaterialien für den Büro- und Gesundheitsbereich. Kommen Sie gerne mit Ihrem Anliegen auf uns zu."
      />
    </div>
    <div class="bottom">
      <form class="form" v-if="!showSuccessMessage" :onSubmit="submitForm">
        <label class="label">Name</label>
        <input
          class="input"
          type="text"
          id="input"
          name="fullName"
          placeholder="Max Mustermann"
        />
        <label class="label">E-Mail Adresse</label>
        <input
          class="input"
          type="text"
          v-model="email"
          id="input"
          name="email"
          placeholder="max@mustermann.net"
        />
        <label class="label">Kontakt</label>
        <input
          class="input"
          type="text"
          id="input"
          name="phone"
          placeholder="+49 151 1234 5678"
        />
        <div class="invalid">{{ invalidEmail }}</div>
        <label class="label">Ihre Nachricht an uns</label>
        <textarea
          class="input"
          id="input"
          name="message"
          placeholder="... Ihre Nachricht an uns"
        />
        <Button name="Absenden" />
      </form>
      <div class="success" v-if="showSuccessMessage">
        <img :src="require('../assets/check.svg')" alt="success" />
        <Headline name="Vielen Dank!" size="medium" class="test" />
        <Text
          content="Wir werden uns umgehend um Ihre Anfrage kümmern und werden uns innerhalb der nächsten 24 Stunden bei Ihnen melden."
        />
      </div>
      <div class="box">
        <div class="center">
          <Headline name="Beatus Office" size="small" />
        </div>
        <a v-for="(item, i) in items" :key="i" class="item" :href="item.link">
          <img :src="item.src" alt="icon" />
          <p>{{ item.text }}</p>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import Headline from "./Headline";
import Button from "./Button";
import Text from "./Text";
import emailjs from "emailjs-com";

const checkForEmail = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g;

export default {
  name: "Contact",
  components: {
    Headline,
    Button,
    Text,
  },
  data() {
    return {
      items: [
        {
          src: require("../assets/house.svg"),
          text: "Schönburgstr. 19/20, 12103 Berlin",
          link: "",
        },
        {
          src: require("../assets/phone.svg"),
          text: "+49 176 615 890 58",
          link: "tel:+4917661589058",
        },
        {
          src: require("../assets/mail.svg"),
          text: "info@beatus-office.de",
          link: "mailto:info@beatus-office.de",
        },
      ],
      showSuccessMessage: false,
      email: "",
      invalidEmail: "",
    };
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      const isEmailValid = this.email.match(checkForEmail);

      if (!isEmailValid) {
        this.invalidEmail = "Bitte geben Sie eine gültige E-Mail an";
      } else {
        this.showSuccessMessage = !this.showSuccessMessage;
        emailjs
          .sendForm(
            "service_fnx0bbd",
            "template_earvbuw",
            e.target,
            "user_eb5qeKaX5BuEVuY92sNze"
          )
          .then(
            (result) => {
              console.log("SUCCESS!", result.status, result.text);
            },
            (error) => {
              console.log("FAILED...", error);
            }
          );
        e.target.reset();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper {
  max-width: 1128px;
  margin: 66px auto;
  padding: 0 20px 58px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    padding: 72px 0px 0px;
  }
}
.top {
  padding-bottom: 142px;
  max-width: 936px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 48px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
    gap: 0;
  }
}
.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 48px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}
.form {
  display: flex;
  flex-direction: column;
  width: 50%;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
    padding: 0 20px;
  }
}
.invalid {
  margin-top: -24px;
  margin-bottom: 16px;
  color: #db4646;
}
.success {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.test {
  margin-bottom: 22px;
}
.label {
  font-size: 16px;
  letter-spacing: 2px;
  font-weight: 800;
  line-height: 24px;
  margin-bottom: 8px;
}
.input {
  padding: 16px;
  margin-bottom: 32px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #bac5c9;
  line-height: 24px;
}
::placeholder {
  color: #bac5c9;
  font-family: "Manrope", sans-serif;
}
textarea {
  font-family: "Manrope", sans-serif;
}
.box {
  background: #0b7aa3;
  color: #fff;
  border-radius: 4px;
  padding: 54px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    width: -webkit-fill-available;
  }
}
.text {
  font-size: 18px;
  line-height: 32px;
}
.item {
  display: flex;
  gap: 18px;
  text-decoration: none;
  color: #fff;
}
.center {
  text-align: center;
}
</style>
