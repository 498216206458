<template lang="">
  <button class="button" @click="onPress">
    {{ name }}
  </button>
</template>
<script>
export default {
  name: "Button",
  props: {
    onPress: {
      type: Function,
    },
    name,
  },
};
</script>

<style lang="scss" scoped>
.button {
  background-color: #0b7aa3;
  width: fit-content;
  padding: 16px 44px;
  border-radius: 4px;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  border: none;
  outline: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #043648;
  }
}
</style>
