<template lang="">
  <div class="wrapper">
    <div class="headline">
      <Headline name="Kunden, die uns vertrauen" size="medium" />
    </div>
    <div class="icons">
      <img
        v-for="icon in icons"
        :key="icon.path"
        :src="icon.path"
        alt="icon"
        class="image"
      />
    </div>
  </div>
</template>

<script>
import Headline from "./Headline";

export default {
  name: "Clients",
  components: {
    Headline,
  },
  data() {
    return {
      icons: [
        { path: require("../assets/apotheke.png") },
        { path: require("../assets/mcdonalds.png") },
        { path: require("../assets/vivantes.png") },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  max-width: 1128px;
  margin: 0 auto;
  padding: 104px 20px 124px;
  display: flex;
  align-items: center;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    padding: 48px 20px 36px;
    flex-direction: column;
  }
}
.headline {
  flex: 1;
}
.icons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  flex: 2;
}

.image {
  max-height: 80px;
  width: auto;

  @media only screen and (max-width: 768px) {
    max-height: 64px;
    gap: 32px;
  }
}
</style>
