<template lang="">
  <div :class="size">
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "Headline",
  props: ["name", "size"],
};
</script>
<style lang="scss" scoped>
.large {
  font-size: 72px;
  line-height: 78px;
  font-weight: 800;
  margin-bottom: 16px;

  @media only screen and (max-width: 600px) {
    font-size: 64px;
  }
}
.medium {
  font-size: 48px;
  line-height: 64px;
  font-weight: 800;

  @media only screen and (max-width: 600px) {
    font-size: 36px;
    line-height: 48px;
    margin-bottom: 24px;
  }
}
.small {
  font-size: 40px;
  line-height: 54px;
  font-weight: 800;
  margin-bottom: 12px;

  @media only screen and (max-width: 600px) {
    font-size: 24px;
  }
}
</style>
