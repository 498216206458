<template lang="">
  <footer>
    <div class="footer__head">
      <div class="footer__head--middle">
        © {{ year }} Beatus Office GmbH. All rights reserved
      </div>
      <div class="footer__head--right">
        Impressum
      </div>
      <!-- <div class="footer__main">
        <div class="footer__main--block">
          Musterfirmenname<br />
          OHG Musterstraße 1<br />
          12345 Musterstadt
        </div>

        <div class="footer__main--block">
          Vertretungsberechtige Gesellschafter: Fred Muster und Hugo Mustermann
        </div>
        <div class="footer__main--block">
          Telefon: 123/123456<br />
          Fax: 123/123457
        </div>
        <div class="footer__main--block">
          E-Mail: info@musternameohg.de
        </div>
        <div class="footer__main--block">
          Registergericht: Amtsgericht<br />
          Musterstadt Registernummer: HRA 12345
        </div>
        <div class="footer__main--block">
          Umsatzsteuer-Identifikationsnummer gem. § 27a UStG: DE 123456789
        </div>
        <div class="footer__main--block">
          Inhaltlich Verantwortlicher: Maximilian Mustermann (Anschrift s.o.)(1)
        </div>
      </div> -->
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
footer {
  background: #e5f8ff;
  padding: 44px 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;

  @media only screen and (max-width: 768px) {
    padding: 44px 20px;
  }
}

.footer__head {
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  &--middle {
    text-align: center;
  }
  &--right {
    text-align: right;

    @media only screen and (max-width: 768px) {
      margin: 20px auto 76px;
      text-align: center;
    }
  }
}
.footer__main {
  max-width: 744px;
  margin: 144px auto 172px;

  @media only screen and (max-width: 768px) {
    margin: 0px;
  }

  &--block {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 32px;

    @media only screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
}
</style>
