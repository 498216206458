<template lang="">
  <div class="wrapper">
    <img class="team" :src="require('../assets/team.svg')" alt="team" />
    <div class="services">
      <Headline name="Wir bieten Ihnen" size="medium" />
      <div class="text">
        <Text
          content="Die Qualität unserer Produkte ist uns sehr wichtig. Unsere Kompetenz
        haben wir langjähriger Erfahrung zu verdanken und Blicken auf ein
        Netzwerk von zufriedenen und treuen Kunden."
        />
      </div>
      <list>
        <li v-for="(services, i) in services" :key="i">
          {{ services.text }}
        </li>
      </list>
    </div>
  </div>
</template>

<script>
import Headline from "./Headline";
import Text from "./Text";

export default {
  name: "Portfolio",
  components: {
    Headline,
    Text,
  },
  data() {
    return {
      services: [
        { text: "hochwertige Produkte" },
        { text: "erstklassigen Service" },
        { text: "langfristige Zusammenarbeit" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 90px 20px 200px;
  max-width: 1128px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;

  @media only screen and (max-width: 768px) {
    padding: 48px 20px 36px;
    flex-direction: column;
  }
}
.team {
  max-width: 50%;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
}
.text {
  margin: 48px 0;
  order: 2;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
}
.services {
  padding: 0 20px;

  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
}
list {
  margin: 48px 0;
  order: 1;

  & > li {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 500;
    word-break: break-all;

    @media only screen and (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 16px;
    }
  }
}
</style>
