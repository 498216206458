<template>
  <header>
    <div class="logo">
      <img src="../assets/logo.svg" alt="logo" />
    </div>
    <div class="wrapper">
      <Headline name="Beatus Office" size="large" />
      <Text
        content="Ihr Händler für hochwertige Verbrauchsmaterialien im Büro- und Gesundheitsbereich."
      />
      <Button name="Schreiben Sie uns" :onPress="() => scroll('contact')" />
    </div>
  </header>
</template>

<script>
import Headline from "./Headline";
import Text from "./Text";
import Button from "./Button";

export default {
  components: {
    Headline,
    Text,
    Button,
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  background-image: url("../assets/bubbles.svg");
  background-repeat: no-repeat;
  height: 768px;
  background-position: top right;
  display: flex;
  flex-direction: column;
  padding: 30px 20px;

  @media only screen and (max-width: 768px) {
    background-position: top left;
    padding: 30px 20px 72px;
    height: auto;
  }

  @media only screen and (min-width: 768px) {
    background-size: contain;
  }
}
.wrapper {
  max-width: 936px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 216px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 0px;
  }
}
.logo {
  margin: 0 auto 134px;

  @media only screen and (max-width: 600px) {
    margin: 0 auto 64px;
  }
}
</style>
