<template>
  <Stage />
  <Clients />
  <Portfolio />
  <Contact />
  <Footer />
</template>

<script>
import Stage from "./components/Stage";
import Clients from "./components/Clients";
import Portfolio from "./components/Portfolio";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Stage,
    Clients,
    Portfolio,
    Contact,
    Footer,
  },
};
</script>

<style>
body {
  margin: 0;
}
#app {
  font-family: "Manrope", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #18191f;
}

.container {
  max-width: 1440px;
  width: 100%;
  border: 2px solid red;
  margin: 0 auto;
  padding: 20px;
}
</style>
