<template lang="">
  <div class="text">
    {{ content }}
  </div>
</template>

<script>
export default {
  name: "Text",
  props: ["content"],
};
</script>

<style lang="scss" scoped>
.text {
  font-size: 18px;
  margin-bottom: 32px;
  max-width: 456px;
  line-height: 32px;

  @media only screen and (max-width: 600px) {
    font-size: 16px;
  }
}
</style>
